import { render, staticRenderFns } from "./ModalPageItemError.vue?vue&type=template&id=d6b0a466&scoped=true"
import script from "./ModalPageItemError.vue?vue&type=script&lang=js"
export * from "./ModalPageItemError.vue?vue&type=script&lang=js"
import style0 from "./ModalPageItemError.vue?vue&type=style&index=0&id=d6b0a466&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6b0a466",
  null
  
)

export default component.exports